@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes floating {
  0% {
    transform: translateY(0);
  }
  30% {
    transform: translateY(-10px);
  }
  50% {
    transform: translateY(4px);
  }
  70% {
    transform: translateY(-15px);
  }
  to {
    transform: translateY(0);
  }
}

.floating {
  animation: floating 6s ease 0s infinite normal none running;
}

@keyframes animated-border {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.animated-border {
  animation: animated-border 3s infinite 0s normal none running;
}
